<template>
  <v-layout max-width max-height class=" flex-column flex-justify-between overflow-hidden" style="background:#fff;">
    <div class=" flex-1 overflow-auto  max-width pt-12 pr-12">
      <v-row no-gutters class="flex-y-center mb-8">
        <v-col cols="12" sm="2" md="2" class=" flex-justify-end">
          邮箱：
        </v-col>
        <v-col cols="12" sm="10" md="10">
          <input v-model="data.emailAddress" class=" flex-1 default-input">
        </v-col>
      </v-row>
      <v-row no-gutters class="flex-y-center mb-8">
        <v-col cols="12" sm="2" md="2" class=" flex-justify-end">
          密码：
        </v-col>
        <v-col cols="12" sm="10" md="10">
          <input v-model="data.emailPassword" type="password" class=" flex-1 default-input">
        </v-col>
      </v-row>
      <v-row no-gutters class="flex-y-center mb-8">
        <v-col cols="12" sm="2" md="2" class=" flex-justify-end">
          Smtp服务器：
        </v-col>
        <v-col cols="12" sm="10" md="10">
          <input v-model="data.smtpServer" class=" flex-1 default-input">
        </v-col>
      </v-row>
      <v-row no-gutters class="flex-y-center mb-8">
        <v-col cols="12" sm="2" md="2" class=" flex-justify-end">
          端口号：
        </v-col>
        <v-col cols="12" sm="10" md="10">
          <input v-model="data.smtpPort" class=" flex-1 default-input">
        </v-col>
      </v-row>
      <v-row no-gutters class=" flex-align-start mb-8">
        <v-col cols="12" sm="2" md="2" class=" flex-justify-end mt-2">
          邮件签名：
        </v-col>
        <v-col cols="12" sm="10" md="10">
          <div style="border: 1px solid #ccc;">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :default-config="toolbarConfig"
              :mode="mode"
            />
            <Editor
              v-model="html"
              :default-config="editorConfig"
              style="height: 360px; overflow-y:auto;"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div>
        </v-col>
      </v-row>
      <!-- <div class=" flex-y-center mb-8">
                <span class=" flex-0 flex-shrink-0 flex-justify-end mr-2 body-1" style="width:110px;">邮箱：</span>
                <input class=" flex-1 default-input" v-model="data.old"/>
            </div>
            <div class=" flex-y-center mb-8">
                <span class=" flex-0 flex-shrink-0 flex-justify-end mr-2 body-1" style="width:110px;">密码：</span>
                <input class=" flex-1 default-input" v-model="data.old"/>
            </div>
            <div class=" flex-y-center mb-8">
                <span class=" flex-0 flex-shrink-0 flex-justify-end mr-2 body-1" style="width:110px;">Smtp服务器：</span>
                <input class=" flex-1 default-input" v-model="data.old"/>
            </div>
            <div class=" flex-y-center mb-8">
                <span class=" flex-0 flex-shrink-0 flex-justify-end mr-2 body-1" style="width:110px;">端口号：</span>
                <input class=" flex-1 default-input" v-model="data.old"/>
            </div> -->
    </div>
    <v-divider />
    <div class=" flex-0 flex-justify-end pa-3">
      <v-btn :loading="btnLoading" dark small color="primary" depressed min-width="100" height="30" @click="submit">
        保存
      </v-btn>
    </div>
    <!-- <v-row no-gutters class=" flex-0 flex-y-center mb-8">
      <v-col cols="12" sm="4" md="4" class=" flex-justify-end" />
      <v-col cols="12" sm="6" md="6">
        <v-btn :loading="btnLoading" dark small color="primary" depressed min-width="100" style="height:30px;" @click="submit">
          保存
        </v-btn>
      </v-col>
    </v-row> -->
  </v-layout>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import '@wangeditor/editor/dist/css/style.css'
export default {
  components: { Editor, Toolbar },
  data() {
    return {
      data: {
      },
      btnLoading: false,
      editor: null,
      html: '',
      toolbarConfig: {
        toolbarKeys: [
          'fontFamily', 'headerSelect', 'bold', 'underline', 'italic', 'bgColor', 'fontSize', 'lineHeight', 'justifyLeft', 'justifyRight', 'justifyCenter', 'insertLink', 'unLink', 'uploadImage', 'undo', 'redo' // 重复
        ]
      },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            server: this.$api.settingUploadImage,
            // config.headers['X-Access-Token'] = localStorage.getItem('access_token') || ''
            headers: {
              'X-Access-Token': localStorage.getItem('access_token') || ''
            },
            fieldName: 'file',
            maxFileSize: 50 * 1024 * 1024, // 50M
            // 单个文件上传成功之后
            onSuccess(file, res) { // JS 语法
              // console.log(`${file.name} 上传成功`, res)
            },

            // 单个文件上传失败
            onFailed(file, res) { // JS 语法
              window.vm_app.$message.error('上传失败！')
              // console.log(`${file.name} 上传失败`, res)
            },

            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) { // JS 语法
              window.vm_app.$message.error('上传失败！')
              // console.log(`${file.name} 上传出错`, err, res)
            },
            // 自定义插入图片
            customInsert(res, insertFn) { // JS 语法
              // res 即服务端的返回结果
              // 从 res 中找到 url alt href ，然后插入图片
              // console.log(res)
              // insertFn(url, alt, href)replace
              insertFn(res.result, res.result, res.result)
            }
          },
          fontFamily: {
            fontFamilyList: [
              '黑体', '楷体', { name: '仿宋', value: '仿宋' }, 'Arial', 'Tahoma', 'Verdana'
            ]
          },
          fontSize: {
            fontSizeList: [
              '12px', '14px', '16px', '20px', '24px', '28px', '32px', '48px', '72px'
            ]
          },
          lineHeight: {
            lineHeightList: ['1', '1.5', '2', '2.5']
          }
        }
        // MENU_CONF['uploadImage']: {
        //     server: '/api/upload',
        // }
      },
      mode: 'default' // or 'simple'
    }
  },
  mounted() {
    this.settingSmtpGet()
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    // 获取信息
    settingSmtpGet() {
      this.$http.get(this.$api.settingSmtpGet, { data: '' }).then(res => {
        this.data.emailAddress = res.result.email_account
        this.data.emailPassword = res.result.email_password
        this.data.smtpServer = res.result.smtp_host
        this.data.smtpPort = res.result.smtp_port
        this.html = res.result.sign_html || ''
        this.$forceUpdate()
      })
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    // 保存
    submit() {
      const param = new URLSearchParams()
      for (const i in this.data) {
        param.append(i, this.data[i] || '')
      }
      param.append('signHtml', this.html || '')
      this.btnLoading = true
      this.$http.put(this.$api.settingSmtpSet, { data: param }).then(res => {
        this.$message.success(res.message)
        this.btnLoading = false
      }).catch(() => {
        this.btnLoading = false
      })
    }
  }
//
}
</script>

<style>

</style>
